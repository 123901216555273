import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'common-learn-more-services-modal',
  templateUrl: './learn-more-services-modal.component.html'
})
export class LearnMoreServicesModalComponent implements OnDestroy {

  routerSubscription: Subscription;

  constructor(private activeModal: NgbActiveModal, private router: Router) {
    this.routerSubscription = this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(e => {
      this.close();
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  close() {
    this.activeModal.close();
  }
}
