import { animate, style, transition, trigger } from "@angular/animations";
import { Component, OnInit, OnDestroy, Renderer2 } from "@angular/core";
import { Subscription } from "rxjs";
import { OverlayService } from "../../services/overlay.service";

@Component({
  selector: "app-search-suggests-overlay",
  templateUrl: "./search-suggests-overlay.component.html",
  styleUrls: ["./search-suggests-overlay.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [style({ opacity: 0 }), animate("250ms", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("250ms", style({ opacity: 0 }))]),
    ]),
  ],
})
export class SearchSuggestsOverlayComponent implements OnInit, OnDestroy {
  showOverlay = false;
  overlaySubscription: Subscription;
  onHomePage: boolean;

  constructor(private overlayService: OverlayService, private renderer: Renderer2) {}

  ngOnInit() {
    this.overlayService.registerOverlay("search-suggests");

    this.overlaySubscription = this.overlayService.subscribeToOverlayUpdates("search-suggests", showOverlay => {
      this.showOverlay = showOverlay;
      this.onHomePage = window.location.pathname === "/" ? true : false;

      if (showOverlay) {
        this.renderer.addClass(document.body, "no-scroll");
      } else {
        this.renderer.removeClass(document.body, "no-scroll");
      }
    });
  }

  ngOnDestroy() {
    this.overlaySubscription.unsubscribe();
  }
}
