import { Image } from './image.model';

export interface IBrandProfile {
    logo: Image;
    brandColor?: any;
}

export class BrandProfile implements IBrandProfile {
    logo: Image;
    brandColor?: any;

    constructor() {
        this.logo = new Image();
        this.brandColor = '';
    }
}
