import { Component } from "@angular/core";
import { HeaderAccountComponent } from "./header-account/header-account.component";
import { HeaderService } from "./header.service";
import { HeaderCartComponent } from "./header-cart/header-cart.component";
import { EnvironmentConfig } from "../../core/core";
import { HeaderListMachineComponent } from "./header-list-machine/header-list-machine.component";

declare var environment: EnvironmentConfig;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  rightSideHeaderItems: any = [];
  leftSideHeaderItems: any = [];

  mobileHeaderItems: any = [HeaderListMachineComponent, HeaderAccountComponent, HeaderCartComponent];

  constructor(private headerService: HeaderService) {
    this.headerService.rightSideHeaderItems.subscribe(headerItems => {
      this.rightSideHeaderItems = headerItems;
    });

    this.headerService.leftSideHeaderItems.subscribe(headerItems => {
      this.leftSideHeaderItems = headerItems;
    });
  }

  navbarImage(): string {
    switch (environment.clientId) {
      case "rubbl.admin":
        return environment.baseImagePath + "/pubweb/admin/images/logos/rubbl-admin-logo.svg";

      case "rubbl.transportation":
        return environment.baseImagePath + "/pubweb/transportation/images/logos/rubbl-transpo-logo.svg";

      case "rubbl.website":
      default:
        return environment.baseImagePath + "/pubweb/marketplace/images/logos/rubbl-navbar.svg";
    }
  }
}
