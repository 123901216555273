<!-- only show for desktop -->
<div class="d-none d-lg-block">
  <form class="form-inline">
    <div class="form-group transparent col-lg-5 col-xxl-3">
      <label for="machine-type"><span class="icon-mag-glass text-grey"></span></label>
      <input
        type="text"
        class="form-control text-white search-term-input"
        id="machine-type"
        autocomplete="off"
        placeholder="Search dozer, excavator, etc"
        name="searchterm"
        #headerSearchInput
        [(ngModel)]="searchRequest.searchTerm"
        (keyup.enter)="onEnter()"
      />
      <div class="search-suggestions">
        <app-search-suggests [searchInput]="headerSearchInput" customClass="search-results-suggestions" (suggestionSelected)="suggestionSelected($event)">
        </app-search-suggests>
      </div>
    </div>
    <div class="form-group transparent">
      <label for="location" class="text-grey d-none d-xl-block">Location</label>
      <common-location-typeahead
        [location]="searchRequest.location.location"
        [showCurrentLocationOption]="false"
        width="100%"
        (locationSelected)="locationSelected($event)"
        inputClass="text-white"
        dropdownClass="header-search"
      ></common-location-typeahead>
      <div class="mobile-bottom-border d-md-none"></div>
    </div>
    <button class="btn btn-outline-secondary" title="Use my location" (click)="useMyLocation()"><i class="icon-delivered text-white"></i></button>
  </form>
</div>
