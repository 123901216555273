<div class="container">
  <div class="row" *ngIf="showLearnMoreForAll">
    <p class="font-italic paragraph-text">Always included</p>
  </div>
  <div class="row">
    <div class="d-flex mb-0">
      <i class="icon-services text-red fs-30 mr-2"></i>
      <p class="section-header">Rubbl Managed Services</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="pl-0 col-md-6" *ngFor="let marketingCard of marketingCards">
      <common-media-object [marketingCard]="marketingCard"></common-media-object>
    </div>
    <!-- <a routerLink="/help/glossary" *ngIf="!showLearnMoreForAll">Learn More</a> -->
  </div>
</div>
