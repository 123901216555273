import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { Observable, Subject, merge, of } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from "rxjs/operators";
import { HttpParams, HttpClient } from "@angular/common/http";
import { LocationTypeahead } from "./location-typeahead.model";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { EnvironmentConfig } from "../../models/environment-config.model";
import { Location } from "../../models/location.model";
import { Address } from "../../models/address.model";
import { LocalStorageService } from "../../services/local-storage.service";

declare var environment: EnvironmentConfig;
@Component({
  selector: "common-location-typeahead",
  templateUrl: "./location-typeahead.component.html",
  styleUrls: ["./location-typeahead.component.scss"],
})
export class LocationTypeaheadComponent implements OnChanges {
  @ViewChild("instance") instance: NgbTypeahead;
  @ViewChild("typeahead") typeahead: ElementRef;

  @Input() location: Location;
  @Input() placeHolderText: string = "Enter City or Zip";
  @Input() showIcon: boolean = false;
  @Input() width: string; // must set parent form-group width for this to work
  @Input() onlyAddresses: boolean = false;
  @Input() dropdownClass: string = "";
  @Input() showFocusBorder: boolean;
  @Input() inputClass: string = "";
  @Output() locationSelected = new EventEmitter<Location>();
  @Input() showCurrentLocationOption: boolean;

  environment: EnvironmentConfig;
  sendEvent: boolean = false;
  model: any;
  availableLocations: LocationTypeahead[];

  constructor(private http: HttpClient, private cd: ChangeDetectorRef, private localStorageService: LocalStorageService) {
    this.environment = environment;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const locationCurrentValue = changes.location?.currentValue as Location;
    if (!locationCurrentValue?.latitude || !locationCurrentValue?.longitude) {
      this.sendEvent = true;

      // if we had a previous location and got rid of it, we need to wipe out the input
      if (changes.location?.previousValue != null) {
        this.model = null;
      }
      return;
    }

    if (locationCurrentValue.address != null) {
      // convert location from api to azure location
      this.model = {
        address: {
          freeformAddress: new Address().toString(locationCurrentValue.address),
        },
        position: {
          lat: locationCurrentValue.latitude,
          lon: locationCurrentValue.longitude,
        },
      };
      this.sendEvent = false;
    }
  }

  onEnter() {
    this.formatter(this.availableLocations[0]);
  }

  formatter = (selectedTypeahead: LocationTypeahead) => {
    if (selectedTypeahead?.address.freeformAddress == "Current Location") {
      if (!this.location) {
        this.locationSelected.emit(null);
      }
      this.sendEvent = true;
      this.cd.detectChanges();
      setTimeout(() => {
        return "Current Location";
      }, 2000);
      return "Current Location";
    }

    if (selectedTypeahead == null || !selectedTypeahead?.position?.lat || !selectedTypeahead?.position?.lon) {
      return "";
    }

    // convert azure location to api location
    const address = {
      address1: `${selectedTypeahead.address.streetNumber || ""} ${selectedTypeahead.address.streetName || ""}`,
      city: selectedTypeahead.address.localName || selectedTypeahead.address.municipality || "",
      stateProvince: selectedTypeahead.address.countrySubdivision || "",
      postalCode: selectedTypeahead.address.postalCode || "",
      countryCode: selectedTypeahead.address.countryCode || "",
    } as Address;

    const location = {
      latitude: selectedTypeahead.position.lat,
      longitude: selectedTypeahead.position.lon,
      name: "",
      address,
    } as Location;

    if (this.sendEvent) {
      this.locationSelected.emit(location);
    }

    this.location = location;

    this.sendEvent = true;
    this.typeahead.nativeElement.blur();
    return selectedTypeahead.address?.freeformAddress?.trim();
  };

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$).pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(term =>
        this.searchMaps(term).pipe(
          tap((results: LocationTypeahead[]) => {
            this.availableLocations = results;
          }),
          catchError(() => {
            return of([]);
          }),
        ),
      ),
    );
  };

  focus$ = new Subject<string>();

  searchMaps(term: string) {
    const userLocationApproved = this.localStorageService.get("user-location");
    if (userLocationApproved === false) {
      // do nothing
    } else {
      if (term === "" && this.showCurrentLocationOption) {
        this.location = null;
        this.formatter(null);
        return of([
          {
            address: {
              freeformAddress: "Current Location",
            },
          },
        ]);
      }
    }

    const params = new HttpParams({
      fromObject: {
        "api-version": "1",
        "subscription-key": this.environment.azureMapKey,
        typeahead: "true",
        query: term,
        limit: this.onlyAddresses ? "10" : "5", // 10 is default. for cities we just want 5 results
        countrySet: "US,CA",
        idxSet: this.onlyAddresses ? "PAD,Addr" : "PAD,Geo",
      },
    });

    return this.http.get(`https://atlas.microsoft.com/search/fuzzy/json`, { params }).pipe(
      map(response => {
        return response["results"].filter(d => d.entityType !== "MunicipalitySubdivision");
      }),
    );
  }

  onBlur() {
    if (!this.location) {
      this.locationSelected.emit(null);
      this.model = null;
    }
  }
}
