import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: 'app-custom-component',
  styleUrls: ['./custom-toast.component.scss'],
  templateUrl: './custom-toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translateY(-100%)',
          opacity: 0,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translateY(-100%)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})

// if you pass in a title when calling the toaster method it will replace the icon
// got the sign off from design that we will never be using the title for toastrs on 12/17/2019
export class CustomToastComponent extends Toast {

  iconClass: string;
  iconClassMappings = {
    "toast-info": "icon icon-checkmark",
    "toast-success": "icon icon-checkmark",
    "toast-error": "icon icon-alert",
    "toast-warning": "icon icon-alert",
  };

  // constructor is only necessary when not using AoT
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
    this.iconClass = this.iconClassMappings[toastPackage.toastType];
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
