import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { routes } from "./routes";

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
      onSameUrlNavigation: "reload",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class RoutesModule {
  constructor() {}
}
