import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SwiperModule } from "ngx-swiper-wrapper";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";

import { MachinesInAreaCarouselComponent } from "./machine-components/machines-in-area-carousel/machines-in-area-carousel.component";
import { MachinesFromOwnerCarouselComponent } from "./machine-components/machines-from-owner-carousel/machines-from-owner-carousel.component";
import { CustomToastComponent, RubblSharedModule } from "@rubbl-shared";
import { MarketingModule } from "@rubbl-marketing";

@NgModule({
  declarations: [MachinesFromOwnerCarouselComponent, MachinesInAreaCarouselComponent],
  imports: [
    CommonModule,
    FormsModule,
    SwiperModule,
    RouterModule,
    RubblSharedModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      preventDuplicates: true,
      toastComponent: CustomToastComponent,
    }),
    ReactiveFormsModule,
    MarketingModule,
  ],
  exports: [CommonModule, MachinesFromOwnerCarouselComponent, MachinesInAreaCarouselComponent, ReactiveFormsModule, RouterModule, SwiperModule],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
