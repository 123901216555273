<div class="modal-header" rt="learn-more-services-modal">
  <div></div>
  <h4 class="mb-0">Learn More</h4>
  <i class="icon-close" id="icon-close" (click)="close()"></i>
</div>

<div class="modal-body p-4">
  <!-- DO NOT REMOVE THE SPAN TAG -->
  <div class="ribbon ribbon-large ribbon-top-right"><span class="bold">Always Included</span></div>
  <common-services-card [showLearnMoreForAll]="false"></common-services-card>
</div>
<div class="modal-footer h-auto d-flex justify-content-center bg-light-grey">
  <button type="button" class="btn btn-outline-dark btn-modal-footer mr-4" (click)="close()">Close</button>
  <button type="button" class="btn btn-secondary btn-modal-footer" (click)="close()">Ok</button>
</div>