import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { CurrencyPipe, DecimalPipe } from "@angular/common";

import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { RoutesModule } from "./routes/routes.module";
import { LayoutModule } from "./layout/layout.module";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { ServiceWorkerModule } from "@angular/service-worker";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { AuthenticationService, EnvironmentConfig } from "@rubbl-core";

declare let environment: EnvironmentConfig;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    LayoutModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId),
    NgxGoogleAnalyticsRouterModule, // https://www.npmjs.com/package/ngx-google-analytics#setup-routing-module
    GoogleTagManagerModule.forRoot({
      id: "GTM-WD8K79F",
    }),
    RoutesModule, // this needs to last, but before the service worker, otherwise we will get blank white page
    ServiceWorkerModule.register("./ngsw-worker.js", {
      enabled: window.location.hostname != "localhost",
      registrationStrategy: "registerImmediately",
    }),
  ],
  providers: [CurrencyPipe, DecimalPipe, AuthenticationService],
  bootstrap: [AppComponent],
})
export class AppModule {}
