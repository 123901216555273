import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { EnvironmentConfig } from "../../../core/core";
import { MarketingCard } from "../marketing-card/marketing-card.model";

declare var environment: EnvironmentConfig;

@Component({
  selector: "common-marketing-cards-with-numbers",
  templateUrl: "./marketing-cards-with-numbers.component.html",
  styleUrls: ["./marketing-cards-with-numbers.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingCardsWithNumbersComponent implements OnInit {
  @Input() marketingCards: MarketingCard[];
  baseImagePath: string = environment.baseImagePath;
  constructor() {}

  ngOnInit(): void {}
}
