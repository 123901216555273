import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { GlossarySection } from "../glossary-section/glossary-section.model";

@Component({
  selector: "common-glossary",
  templateUrl: "./glossary.component.html",
  styleUrls: ["./glossary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlossaryComponent {
  @Input() glossarySections: GlossarySection[];

  constructor() {}
}
