export interface IFileType {
    name: string;
    mimeType: string[];
}

export class FileType implements IFileType {
    name: string;
    mimeType: string[];
    constructor() {
        this.name = '';
        this.mimeType = [];
    }
}

export interface IStorage {
    storageLocation: string;
    createdDateTime: Date;
    storagePath: string;
    fileType: FileType;
    fileSize: number;
    id?: any;
    isActive: boolean;
    itemType: string[];
    partitionKey: string;
}

export class Storage implements IStorage {
    storageLocation: string;
    createdDateTime: Date;
    storagePath: string;
    fileType: FileType;
    fileSize: number;
    id?: any;
    isActive: boolean;
    itemType: string[];
    partitionKey: string;

    //  Properties for UI
    get displayName(): string {
        if (this.storagePath === "") {
            return "";
        }
        const storagePathSplit = this.storagePath.split('/');
        return storagePathSplit[storagePathSplit.length - 1];
    }

    constructor(items?: IStorage) {
        this.storageLocation = items?.storageLocation || 'BLOB';
        this.createdDateTime = items?.createdDateTime || new Date();
        this.storagePath = items?.storagePath || '';
        this.fileType = items?.fileType || new FileType();
        this.fileSize = items?.fileSize || 0;
        this.isActive = items?.isActive !== undefined ? items.isActive : true;
        this.itemType = items?.itemType || [];
        this.partitionKey = items?.partitionKey || '';
    }
}
