<div class="modal-header align-items-baseline px-0">
  <div><i *ngIf="counter > 0 && counter != steps.length - 1" class="icon-arrow-left p-3 cursor-pointer" (click)="onPrevious()"></i></div>
  <h4 class="mb-0" [class.margin-left-header]="counter > 0 && counter != steps.length - 1" [class.text-green]="counter == steps.length - 1">
    <i class="icon-checkmark mr-2" *ngIf="counter == steps.length - 1"></i>{{ steps[counter].header }}
  </h4>
  <div></div>
</div>
<!--  [@animSlider]="counter" -->
<div style="margin-bottom: 85px">
  <div *ngFor="let step of steps; index as i">
    <common-add-to-cart-attachments
      *ngIf="step.name == 'attachments' && counter == i && attachmentsFromIndex"
      [machineId]="machine.id"
      [attachments]="machine.relatedAttachments"
      [attachmentsFromIndex]="attachmentsFromIndex"
    ></common-add-to-cart-attachments>
    <common-add-to-cart-dates
      *ngIf="step.name == 'startDate' && counter == i"
      [startDate]="startDate"
      [duration]="duration"
      [zipcode]="zipcode"
      [rpoEnabled]="machine.rpoEnabled"
      [rateSchedules]="machine.rateSchedules"
      (startDateSelected)="startDateSelected($event)"
      (durationSelected)="durationSelected($event)"
      (zipcodeChange)="zipcodeChange($event)"
    ></common-add-to-cart-dates>
    <common-add-to-cart-details
      *ngIf="step.name == 'addToCart' && counter == i"
      [cartItem]="cartItem"
      [comingFromDateDurationStep]="true"
      (goToPreviousStep)="onPrevious()"
    ></common-add-to-cart-details>
    <!-- for desktop we still want to show the rental details -->
    <div class="">
      <common-add-to-cart-details
        *ngIf="step.name == 'continueCheckoutStep' && counter == i"
        [cartItem]="cartItem"
        [showPhoneNumberInput]="showPhoneNumberInput"
        [comingFromDateDurationStep]="true"
      ></common-add-to-cart-details>
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button class="b-light-grey book-by-phone btn btn-modal-footer h-100" (click)="activeModal.dismiss(steps[counter].name)">
    {{ steps[counter].cancelButtonText }}
  </button>
  <common-button-spinner
    class="h-100"
    buttonClass="add-to-cart btn btn-modal-footer btn-secondary h-100"
    [text]="steps[counter].buttonText"
    (clickChange)="steps[counter].buttonMethod()"
    [disabled]="steps[counter].buttonDisabled()"
    [loading]="loading"
    svgSpinnerHeight="26px"
  ></common-button-spinner>
</div>
