<div class="bg-white">
  <div class="modal-header" rt="how-it-works-modal-header" ngbAutofocus>
    <div class="w-100 text-center">
      <h4 class="mb-0">{{ modalTitle }}</h4>
    </div>
    <i class="icon-close text-grey" id="icon-close" (click)="activeModal.dismiss()"></i>
  </div>
  <div class="bg-grey w-100 pt-1"></div>
  <div class="d-flex flex-column px-3">
    <p class="m-3 mt-5 text-grey font-weight-light">{{ subTitle }}</p>
    <common-marketing-cards-with-numbers [marketingCards]="howItWorksCards"></common-marketing-cards-with-numbers>
    <div class="bg-grey w-100 pt-1"></div>
    <div class="d-flex justify-content-center p-3">
      <button class="btn btn-outline-dark btn-modal-footer mr-3 font-weight-bold" (click)="activeModal.dismiss()">{{ modalCancelText }}</button>
      <a href="{{ modalLinkAddress }}">
        <button class="btn text-white bg-red btn-modal-footer ml-3 font-weight-bold">{{ modalLinkText }}</button>
      </a>
    </div>
  </div>
</div>
