import { MarketingCard } from "../marketing";

export const HowRubblWorksSectionData: MarketingCard[] = [
  {
    title: "Find it",
    body: "Search an inventory of newer, well-maintained, reliable machines from vetted contractors across the country ready to work on your jobsite. ",
    // imagePath: "/pubweb/marketplace/images/landing-pages/how-it-works/find.jpg",
  },
  {
    title: "Rent it",
    body: "Select the machines you need and checkout in a few clicks. Have confidence knowing the machines you rented will show up equipped and on time.",
    // imagePath: "/pubweb/marketplace/images/landing-pages/how-it-works/rent.jpg",
  },
  {
    title: "Use it",
    body: "Use machines as long as you need them, we’ll handle everything in between to make sure they stay in optimal working condition while on rent.",
    // imagePath: "/pubweb/marketplace/images/landing-pages/how-it-works/use.jpg",
  },
];

export const ServicesMarketingCardsData: MarketingCard[] = [
  {
    iconClass: "icon-insurance",
    title: "Damage & Liability Coverage",
    listItems: ["$2,500 damage deductible per incident", "Multiple 50-point machine inspections during the rental"],
  },
  {
    iconClass: "icon-service",
    title: "Ongoing Maintenance",
    listItems: ["Proactively scheduled preventative maintenance every 250 hours", "Maintenance services coordinated by a dedicated Rubbl service manager"],
  },
  {
    iconClass: "icon-transportation",
    title: "Logistics & GPS",
    listItems: ["Scheduled transportation and coordination for all your rentals", "GPS location monitoring and hourly machine usage tracking"],
  },
  {
    iconClass: "icon-damage-rec",
    title: "Service & Repair",
    listItems: ["Reliable service pros on standby to resolve any issues that arise", "Nationwide network of certified mechanics to minimize downtime"],
  },
];
