import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { GlossarySection } from "./glossary-section.model";

@Component({
  selector: "common-glossary-section",
  templateUrl: "./glossary-section.component.html",
  styleUrls: ["./glossary-section.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlossarySectionComponent {
  @Input() glossarySection: GlossarySection;

  constructor() {}
}
