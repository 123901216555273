import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { EnvironmentConfig, LogService, SeoRouteData, SeoService } from "@rubbl-core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { ToastrService } from "ngx-toastr";
import { filter, map, mergeMap } from "rxjs/operators";

declare let environment: EnvironmentConfig;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  toastrQueryStrings = ["Reset link sent."];
  t: boolean;

  constructor(
    private logService: LogService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private gtmService: GoogleTagManagerService,
  ) {
    this.logService.info(this.constructor.name, "Starting app");
  }

  ngOnInit(): void {
    setTimeout(() => {
      // add gtm after we load
      this.gtmService.addGtmToDom();
    }, 2000);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data),
      )
      .subscribe(data => {
        this.seoService.setSeo(data as SeoRouteData);
      });

    document.addEventListener("click", e => {
      const target = e.target as HTMLElement;
      const href = target.getAttribute("href");
      if (target.tagName === "A" && (!href || href == "#")) {
        e.preventDefault();
      }
    });

    this.checkForToastr();
  }

  checkForToastr(): void {
    // check if there is an toastr message to show
    const urlParams = new URLSearchParams(window.location.search);
    if (this.toastrQueryStrings.includes(urlParams.get("toastrMessage"))) {
      setTimeout(
        () =>
          this.toastrService.success(urlParams.get("toastrMessage"), null, {
            positionClass: "toast-top-center",
          }),
        800,
      );
      window.history.replaceState({}, window.document.title, window.location.origin);
    }
  }
}
