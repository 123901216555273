<ng-container *ngIf="machines != null && machines.length > 0">
  <div class="mt-5">
    <app-card-carousel
      header="Featured Machines"
      cardComponentName="MachineCardAdvancedComponent"
      [slidesPerViewConfig]="slidesPerViewConfig"
      [cardData]="machines"
    >
    </app-card-carousel>
  </div>
</ng-container>
