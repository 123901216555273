<div class="d-lg-flex flex-wrap align-items-center pt-4" rt="inventory-images-header">
  <h3>Listing Photos</h3>
  <div class="ml-auto" *ngIf="showAdminActions">
    <common-icon-button rt="inventory-images-download-photo" iconClass="icon-import" buttonText="Download all photos" (click)="downloadAllPhotos()">
    </common-icon-button>
    <input class="d-none" type="file" accept="image/*" (change)="onMultipleImagesChange($event)" multiple #multipleImagesInput />
  </div>
</div>

<ol class="font-weight-light">
  <li>Include all angles of the machine</li>
  <li>Make sure to step back far away to capture the whole machine</li>
</ol>

<div class="row">
  <div class="col-lg-6 col-xl-3 mt-3" *ngFor="let image of images; index as i">
    <div class="card">
      <div class="card-header d-flex">
        <div>
          {{ image.name }}
          <span *ngIf="inventoryItem.coverImagePosition == i">(Cover photo)</span>
        </div>
        <div class="ml-auto" *ngIf="imagePaths[i] != '' && imagePaths[i] != null">
          <div ngbDropdown placement="bottom-right" class="d-inline-block">
            <i class="icon-peapod text-green cursor-pointer" ngbDropdownToggle></i>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="updateCoverImage(i)">Make Cover</button>
              <button ngbDropdownItem (click)="deleteImage(i)">Delete</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <img class="w-100 img-fit br-5" *ngIf="imagePaths[i] != '' && imagePaths[i] != null" [src]="imagePaths[i]" (load)="imgOnLoad($event)" />
        <div
          *ngIf="imagePaths[i] == '' || imagePaths[i] == null"
          class="d-flex justify-content-center align-items-center image-thumbnail-add"
          (click)="singleImageInput.value = null; setIndexForSingle(i); singleImageInput.click()"
        >
          <figure>
            <i class="icon-large icon-stepper-add text-light-grey ml-3 mr-1 cursor-pointer text-green" rt="upload-image-component-add-image"></i>
            <figcaption>Add Photo</figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>

  <input class="d-none" type="file" accept="image/*" (change)="onSingleImageChange($event)" #singleImageInput />
</div>
<ng-template #modalContent let-modal>
  <div class="modal-header">
    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" (click)="modal.dismiss()">Close</button>
    <button type="button" class="btn btn-secondary" (click)="saveCroppedPhoto(modal)">Save photo</button>
  </div>

  <img style="max-width: 100%" [src]="imgCropperSrc" id="croppr" #croppr (load)="imageLoadedForCropper($event)" />
</ng-template>
