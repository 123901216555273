import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, concatMap, forkJoin, iif, map, mergeMap, Observable, of, Subject, switchMap, tap } from "rxjs";
import { RentalInfo, TaxonomyCategory, TaxonomyManufacturer, TaxonomyModel } from "../models/taxonomy.model";

@Injectable({
  providedIn: "root",
})
export class TaxonomyService {
  private categories: TaxonomyCategory[] = [];
  categories$: BehaviorSubject<TaxonomyCategory[]> = new BehaviorSubject<TaxonomyCategory[]>([]);

  private manufacturers: TaxonomyManufacturer[] = [];
  manufacturers$: BehaviorSubject<TaxonomyManufacturer[]> = new BehaviorSubject<TaxonomyManufacturer[]>([]);

  private models: TaxonomyModel[] = [];
  models$: BehaviorSubject<TaxonomyModel[]> = new BehaviorSubject<TaxonomyModel[]>([]);

  constructor(private httpClient: HttpClient) {}

  init() {
    return forkJoin([this.getCategories(), this.getManufacturers(), this.getModels()]);
  }

  getCategories(): Observable<TaxonomyCategory[]> {
    if (!this.categories.length) {
      return this.httpClient.get<TaxonomyCategory[]>("https://company.rubbl.com/pubweb/json/taxonomy/machine-categories.json").pipe(
        tap(result => {
          this.categories = result;
          this.categories$.next(result);
        }),
      );
    }

    this.categories$.next(this.categories); // probably dont need
    return of(this.categories);
  }

  getManufacturers() {
    if (!this.manufacturers.length) {
      return this.httpClient.get<TaxonomyManufacturer[]>("https://company.rubbl.com/pubweb/json/taxonomy/machine-manufacturers.json").pipe(
        tap(results => {
          this.manufacturers = results;
        }),
      );
    }

    this.manufacturers$.next(this.manufacturers); // probably dont need
    return of(this.manufacturers);
  }

  filterManufacturers(categoryName?: string) {
    if (categoryName) {
      const category = this.categories.find(d => d.categoryName == categoryName);
      this.manufacturers$.next(this.manufacturers.filter(d => d.categoryIds.includes(category.categoryId)));
    } else {
      this.manufacturers$.next(this.manufacturers);
    }
  }

  getModels() {
    if (!this.models.length) {
      return this.httpClient.get<TaxonomyModel[]>("https://company.rubbl.com/pubweb/json/taxonomy/machine-models.json").pipe(
        tap(results => {
          for (let manufacturer of this.manufacturers) {
            for (var i = 0; i < manufacturer.categoryIds.length; i++) {
              const firstModel = results.find(d => d.categoryId == manufacturer.categoryIds[i] && d.manufacturerId == manufacturer.manufacturerId);
              if (!firstModel) {
                manufacturer.categoryIds.splice(i, 1);
              }
            }
          }
          this.models = results;
        }),
      );
    }

    this.models$.next(this.models); // probably dont need
    return of(this.models);
  }

  filterModels(categoryName?: string, manufacturerName?: string) {
    if (manufacturerName && categoryName) {
      const category = this.categories.find(d => d.categoryName == categoryName);
      const manufacturer = this.manufacturers.find(d => d.manufacturerName == manufacturerName);
      setTimeout(() => {
        this.models$.next(this.models.filter(d => d.manufacturerId == manufacturer.manufacturerId && d.categoryId == category.categoryId));
      }, 0);
    } else {
      this.models$.next(this.models);
    }
  }

  getRentalInfo(modelId: number, state: string = "CO", country: string = "US"): Observable<RentalInfo> {
    const model = this.models.find(d => d.modelId == modelId);
    return this.httpRentalInfo(model, state, country).pipe(
      mergeMap(rentalInfo => {
        const lastRevisionDate = rentalInfo.nationalAverages[rentalInfo.nationalAverages.length - 1]?.revisionDate || null;
        if (!lastRevisionDate) {
          return forkJoin({ original: of(rentalInfo), new: of(null) });
        }

        return forkJoin({ original: of(rentalInfo), new: this.httpRentalInfo(model, state, country, lastRevisionDate) });
      }),
      map(rentalInfos => {
        const original = rentalInfos.original;
        if (rentalInfos.new && rentalInfos.new.nationalAverages?.length) {
          let newNational = rentalInfos.new.nationalAverages;
          newNational.shift();
          original.nationalAverages = original.nationalAverages.concat(newNational);
        }

        if (rentalInfos.new && rentalInfos.new.regionalAverages?.length) {
          let newRegional = rentalInfos.new.regionalAverages;
          newRegional.shift();
          original.regionalAverages = original.regionalAverages.concat(newRegional);
        }

        const actualEarnings = d => {
          d.monthlyRate = d.monthlyRate * 0.7;
          d.weeklyRate = d.weeklyRate * 0.7;
        };

        original.nationalAverages?.map(actualEarnings);
        original.regionalAverages?.map(actualEarnings);
        return original;
      }),
    );
  }

  getModel(modelId: number) {
    return this.models.find(d => d.modelId == modelId);
  }

  searchModels(modelName: string) {
    return this.models.filter(d => d.modelName.toLowerCase().startsWith(modelName));
  }

  calculateRecommendedRate(rentalRate: number): number {
    let newRentalRate = 0;
    let percentOff = 0.3;
    if (rentalRate > 5000) percentOff = 0.2;
    if (rentalRate > 10000) percentOff = 0.1;
    if (rentalRate > 15000) percentOff = 0.05;
    if (rentalRate > 20000) percentOff = 0.03;

    try {
      const amountToTakeOff = rentalRate * percentOff;
      newRentalRate = Math.floor(rentalRate - amountToTakeOff);
    } catch (e) {
      newRentalRate = rentalRate;
    }
    return newRentalRate;
  }

  httpRentalInfo(model: TaxonomyModel, state: string = "CO", country: string = "US", revisionDate: string = ""): Observable<RentalInfo> {
    const params = {
      sizeClassId: model.sizeClassId,
      state,
      country,
    };

    if (revisionDate) {
      params["revisionDate"] = revisionDate;
    }
    return this.httpClient.get<RentalInfo>(`https://equipmentwatchapi.com/v1/rental/rentalrates`, {
      params,
      headers: new HttpHeaders({
        "x-api-key": "bb45d1bd8e52462ebbe0a7b1bbb522fd",
      }),
    });
  }
}
