import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EquipmentCategoryPipe } from "./pipes/equipment-category.pipe";
import { SpecMeasurementPipe } from "./pipes/spec-measurement.pipe";
import { ChargePipe } from "./pipes/charge.pipe";
import { ShippingDimensionPipe } from "./pipes/shipping-dimension.pipe";
import { RouterModule } from "@angular/router";
import { MachineCategoryCardComponent } from "./components/machine-category-card/machine-category-card.component";
import { CoreModule } from "../core/core.module";
import { RubblSharedModule } from "../rubbl-shared/rubbl-shared.module";
import { SwiperModule } from "ngx-swiper-wrapper";
import { NgbCarouselModule, NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { AccountModule } from "../account/account.module";
import { FormsModule } from "../forms/forms.module";
import { FormsModule as AngularForms } from "@angular/forms";
import { MachineCategoryPipe } from "./pipes/machine-category.pipe";
import { UnitStringPipe } from "./pipes/unit-string.pipe";
import { MachineSummaryComponent } from "./components/machine-summary/machine-summary.component";
import { InventoryImagesComponent } from "./components/inventory-images/inventory-images.component";
import { MakeModelDropdownComponent } from "./components/make-model-dropdown/make-model-dropdown.component";
import { MachineRpoTableComponent } from "./components/machine-rpo-table/machine-rpo-table.component";
import { SpecificationEditComponent } from "./components/specification-edit/specification-edit.component";
import { InventoryImagesPrivateComponent } from "./components/inventory-images-private/inventory-images-private.component";
import { UpdateMachineStatusModal } from "./components/update-machine-status-modal/update-machine-status-modal";

@NgModule({
  declarations: [
    MachineCategoryPipe,
    UnitStringPipe,
    EquipmentCategoryPipe,
    SpecMeasurementPipe,
    ChargePipe,
    ShippingDimensionPipe,
    MachineCategoryCardComponent,
    MachineSummaryComponent,
    InventoryImagesComponent,
    MakeModelDropdownComponent,
    MachineRpoTableComponent,
    SpecificationEditComponent,
    InventoryImagesPrivateComponent,
    UpdateMachineStatusModal,
  ],
  imports: [CommonModule, RouterModule, RubblSharedModule, CoreModule, NgbCarouselModule, AccountModule, FormsModule, AngularForms, NgbDropdownModule],
  exports: [
    MachineCategoryPipe,
    UnitStringPipe,
    EquipmentCategoryPipe,
    SpecMeasurementPipe,
    ChargePipe,
    ShippingDimensionPipe,
    SwiperModule,
    MachineCategoryCardComponent,
    MachineSummaryComponent,
    InventoryImagesComponent,
    MakeModelDropdownComponent,
    MachineRpoTableComponent,
    SpecificationEditComponent,
    InventoryImagesPrivateComponent,
    UpdateMachineStatusModal,
  ],
  providers: [MachineCategoryPipe, UnitStringPipe, EquipmentCategoryPipe, SpecMeasurementPipe, ChargePipe, ShippingDimensionPipe],
})
export class InventoryModule {}
