import { Injectable } from "@angular/core";
import * as basicLightbox from "basiclightbox";
import { WindowService, WindowSizes } from "../../core/services/window.service";

@Injectable({
  providedIn: "root",
})
export class VideoLightboxService {
  videoHeight: string = "576";

  constructor(private windowService: WindowService) {
    this.videoHeight = this.windowService.windowSizeBSubject.value == WindowSizes.Mobile ? "280" : "576";
  }

  create(videoUrl: string) {
    basicLightbox
      .create(
        ` <iframe width="1024" height="${this.videoHeight}" src="${videoUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
        {
          onClose: () => {
            window["HubSpotConversations"]?.widget?.refresh();
          },
          onShow: () => {
            window["HubSpotConversations"]?.widget?.remove();
          },
        },
      )
      .show();
  }
}
