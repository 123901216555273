<div class="d-flex align-items-center custom-toast" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="icon-container mr-3">
    <i *ngIf="title == null" [ngClass]="iconClass"></i>
    <span *ngIf="title != null" class="text-white">{{ title }}</span>
  </div>
  <div
    class="text-container text-dark-grey"
    *ngIf="message"
    role="alert"
    aria-live="polite"
    [class]="options.messageClass"
    [attr.aria-label]="message"
    [innerHtml]="message"
  ></div>
  <div class="close-container ml-auto mr-3">
    <i class="icon-close close"></i>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
