import { NgModule } from "@angular/core";

import { LayoutComponent } from "./layout.component";
import { LayoutModule as CommonLayoutModule } from "@rubbl-layout";
import { RouterModule } from "@angular/router";
import { NgProgressModule } from "ngx-progressbar";
import { MarketingModule } from "@rubbl-marketing";

@NgModule({
  imports: [CommonLayoutModule, RouterModule, NgProgressModule, MarketingModule],
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
})
export class LayoutModule {}
