import { Component, ChangeDetectionStrategy, Input, OnInit } from "@angular/core";
import { MarketingCard } from "../marketing-card/marketing-card.model";
import { GlossaryModalComponent } from "../glossary-modal/glossary-modal.component";
import { ModalService } from "../../../core/core";
import { RubblManagedServicesData } from "../../data/rental-terms.data";
import { ServicesMarketingCardsData } from "../../data/marketing-material.data";

@Component({
  selector: "common-services-card",
  templateUrl: "./services-card.component.html",
  styleUrls: ["./services-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesCardComponent implements OnInit {
  @Input() showLearnMoreForAll: boolean = true;
  @Input() openModalForLearnMore: boolean = false;

  marketingCards: MarketingCard[] = ServicesMarketingCardsData;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    if (!this.showLearnMoreForAll) {
      this.marketingCards.forEach(d => {
        delete d.linkText;
        delete d.linkUrl;
      });
    }

    if (this.openModalForLearnMore) {
      this.marketingCards.forEach(d => {
        delete d.linkUrl;
        d.linkAction = () => {
          this.openGlossaryModal();
        };
      });
    }
  }

  openGlossaryModal() {
    const modalRef = this.modalService.fullPageModal(GlossaryModalComponent);
    modalRef.componentInstance.glossarySections = [RubblManagedServicesData];
  }
}
