<nav class="navbar navbar-expand-md fixed-top navbar-light bg-dark" rt="app-header">
  <a class="navbar-brand" routerLink="/">
    <img [src]="navbarImage()" />
  </a>

  <!-- Left side header items -->
  <div class="collapse navbar-collapse mr-4">
    <ul class="navbar-nav w-100">
      <ng-container *ngFor="let headerItem of leftSideHeaderItems">
        <li class="nav-item w-100">
          <ng-container *ngComponentOutlet="headerItem"></ng-container>
        </li>
      </ng-container>
    </ul>
  </div>

  <!-- Mobile header items -->
  <ul class="navbar-nav ml-auto d-lg-none mobile-nav">
    <ng-container *ngFor="let headerItem of mobileHeaderItems; let last = last">
      <li *ngIf="last" class="last-header-item-container"></li>
      <li class="nav-item" rt="header-mobile-component">
        <ng-container *ngComponentOutlet="headerItem"></ng-container>
      </li>
    </ng-container>
  </ul>

  <!-- Right side header items -->
  <div class="d-none d-lg-block">
    <ul class="navbar-nav ml-auto">
      <ng-container *ngFor="let headerItem of rightSideHeaderItems; let last = last">
        <li *ngIf="last" class="last-header-item-container test"></li>
        <li class="nav-item" rt="header-desktop-component">
          <ng-container *ngComponentOutlet="headerItem"></ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
