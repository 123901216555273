<section class="glossary-section" [id]="glossarySection.anchorId">
  <div class="row">
    <div class="col-md-6">
      <h3>{{ glossarySection.header }}</h3>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-lg-6" *ngFor="let sectionItem of glossarySection.items">
          <div class="section-item mb-4">
            <div class="section-header mb-2 mb-md-4">
              {{ sectionItem.header }}
            </div>
            <div class="text-grey paragraph-text">
              <p *ngFor="let paragraph of sectionItem.body" class="text-grey paragraph-text" [innerHtml]="paragraph"></p>

              <div *ngIf="sectionItem.bulletPoints">
                <p *ngFor="let bulletPoint of sectionItem.bulletPoints" [innerHtml]="'- ' + bulletPoint"></p>
              </div>

              <div *ngIf="sectionItem.asterisks">
                <!-- we used to have asteriks alright, too lazy to change name of property -->
                <p class="font-italic" *ngFor="let asterisk of sectionItem.asterisks" [innerHtml]="asterisk"></p>
              </div>
              <ng-container *ngIf="sectionItem.table">
                <p class="text-dark">{{ sectionItem.table.header }}</p>
                <div class="bg-light-grey p-3" *ngIf="sectionItem.table">
                  <div *ngFor="let row of sectionItem.table.data; last as isLast">
                    <div class="d-flex" [class.text-dark]="isLast">
                      <div class="mr-auto">{{ row.key }}</div>
                      <div [class.bold]="isLast">{{ row.value }}</div>
                    </div>

                    <div class="bb-light my-3" *ngIf="!isLast"></div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
