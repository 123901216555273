import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MarketingCard } from '../marketing-card/marketing-card.model';

@Component({
  selector: 'common-media-object',
  templateUrl: './media-object.component.html',
  styleUrls: ['./media-object.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaObjectComponent {

  @Input() marketingCard: MarketingCard;
  @Input() indentBody: boolean = false;
  @Input() textGrey: boolean = false;

  constructor() { }

  action(marketing: MarketingCard) {
    marketing.linkAction();
  }
}
