import { GlossarySection } from "../marketing";

export const PricingAndCostsData: GlossarySection = {
  anchorId: "pricing",
  header: "Pricing & Costs",
  items: [
    {
      header: "Rental Rates",
      body: [
        "Rental rates can be set or reset at any time by the equipment Owner. The Owner will be provided with suggested market rental rates for their area and each specific equipment type. Suggested rates are based on EquipmentWatch’s AED Green Book offering. AED provides up-to-date average advertised rental rates sourced from over 500 rental companies across the United States and Canada.",
        "Chosen rental rates can not be lowered more than 20% below the suggested rental rate, in order to maintain a fair market environment.",
        "Read more about how AED Green Book calculates nation-wide rental rates <a href='https://equipmentwatch.com/retail-rental-rates/' target='_blank' rel='nofollow'>here</a>.",
      ],
    },
    {
      header: "Taxes",
      body: [
        "The Rubbl platform is configured to maintain strict compliance with sales and use tax calculations across a number of states, regions, and municipalities. The platform includes direct API integration with 3rd party cloud-based sales and use tax calculation system, <a href='https://www.avalara.com' target='_blank' rel='nofollow'>Avalara AvaTax</a>.",
        "Sales and use tax is automatically charged to the Renter and remitted by Rubbl to the correct tax authority. Sales tax calculations take into account both equipment origin and destination to provide accurate calculation across differing tax jurisdictions, such as county or state locales. As a final assertion of compliance, Rubbl has contracted with 3rd party tax consulting firm, <a href='https://rsmus.com' target='_blank' rel='nofollow'>RSM</a> to identify and manage specialized tax requirements, which will be applied to the AvaTax calculation system.",
        "Equipment property taxes are the sole responsibility of the equipment Owner. In limited locations, special ownership taxes may exist, such as the Colorado Special Mobile Machinery(SMM) tax. These taxes may be included in the AvaTax calculation.",
      ],
    },
    {
      header: "Rubbl Managed Services",
      body: ["Services are charged monthly at 14% of the total monthly equipment rate (including machines and attachments)."],
    },
    {
      header: "Transportation",
      body: [
        "You'll receive a transportation quote for the delivery of each machine within 24 hours of placing an order. The final transportation cost will appear on the monthly billing statement shortly after machine delivery.",
      ],
    },
    {
      header: "Post-Rental Costs",
      body: [
        "Any post-rental activity will be charged at the end of the rental and will appear on the final billing statement. Post-rental activity includes items such as:",
      ],
      bulletPoints: ["Hourly usage overages", "Refueling fees", "Cleaning fees (E.g. excessive dirt on the gear)", "Any other service costs"],
    },
  ],
} as GlossarySection;

export const RubblManagedServicesData: GlossarySection = {
  anchorId: "services",
  header: "Rubbl Managed Services",
  items: [
    {
      header: "Overview",
      body: [
        "Rubbl is your single service partner and provider, coordinating all of your communications, maintenance, logistics, payment, and support on every rental.",
        "<i>Always included</i>",
      ],
    },
    {
      header: "Damage & Liability Coverage",
      bulletPoints: ["$2,500 damage deductible per incident", "Multiple 50-point machine inspections during the rental"],
    },
    {
      header: "Ongoing Maintenance",
      bulletPoints: ["Proactively scheduled preventative maintenance every 250 hours", "Maintenance services coordinated by a dedicated Rubbl service manager"],
    },
    {
      header: "Logistics & GPS",
      bulletPoints: ["Scheduled transportation and coordination for all your rentals", "GPS location monitoring and hourly machine usage tracking"],
    },
    {
      header: "Service & Repair",
      bulletPoints: ["Service pros on standby to resolve any issues that arise", "Nationwide network of certified mechanics to minimize downtime"],
    },
  ],
} as GlossarySection;

export const UsageAndExtensionsData: GlossarySection = {
  anchorId: "usage",
  header: "Usage, Extensions, & Cancelation",
  items: [
    {
      header: "Usage Limit",
      body: [
        "Equipment can be used for 160 hours per month throughout the length of the rental. Total usage is calculated cumulatively over the entire rental, not calculated within each month the equipment is used.",
        "For example, a 3-month rental may use 480 hours across all three months. The following example would be within the 160-hour monthly limit:",
      ],
      table: {
        header: "Usage Limit Example",
        data: [
          { key: "Month 1", value: "100 hours of use" },
          { key: "Month 2", value: "300 hours of use" },
          { key: "Month 3", value: "80 hours of use" },
          { key: "3 Month Total", value: "480 hours of use" },
        ],
      },
    },
    {
      header: "Overages",
      body: [
        "Any hours over the cumulative 160-hour usage limit across the entire rental period will be charged at the end of the rental and will appear on the final statement. The hourly overage rate is based on the same daily rental rate charged for the machine being used. Overage rates will be charged using the following calculation:",
        "<i>[hourly rental rate] x [cumulative overage hours across the rental period] = [overage charges]</i>",
        "For example, if a $9,000 monthly rental was used for 200 hours across 1 month (40 hours over the 160 maximum allowable monthly usage), the overage charge would be as follows:",
      ],
      table: {
        header: "Overages Example",
        data: [
          { key: "Monthly Rate (includes 160 hours)", value: "$9,000" },
          { key: "Overage Charge (40 overage hours @ $56.25)", value: "$2,250" },
          { key: "Total Monthly Charge", value: "$10,000" },
        ],
      },
    },
    {
      header: "Early Returns",
      body: ["Equipment may be returned prior to the estimated rental end date. Minimum rental length is 14 days."],
    },
    {
      header: "Extensions",
      body: [
        "Renters are free to extend rentals as needed. Extension requests must be made 72 hours prior to the estimated rental end date or the equipment could be reclaimed.",
      ],
    },
    {
      header: "Cancellation",
      body: [
        "To receive a full refund, cancellations must be made at least 24 hours before the first machine in the order is picked up. Charges may apply for cancellations made after this period.",
      ],
    },
  ],
} as GlossarySection;

export const LiabilityData: GlossarySection = {
  anchorId: "insurance",
  header: "Liability Coverage",
  items: [
    {
      header: "Requirements",
      body: [
        "We require verified proof of general liability insurance coverage in order to rent equipment on Rubbl. Verified proof of Business Auto Liability is also required if renting on-highway vehicles such as water trucks, dump trucks, service trucks, or fuel/lube trucks. Polices must have $1 Million per occurrence or $2 Million aggregate in coverage minimum.",
        "Coverage verification is a one-time step that will not need to be repeated. Please allow Rubbl 24 hours to verify coverage.",
        "Coverage is verified and managed by <a href='https://jtbatesgroup.com/' target='_blank' rel='nofollow'>JT Bates Group</a>. All coverage information including certificates, renewals, cancellations, and revisions will be sent directly to JT Bates Group.",
        "<a href='https://company.rubbl.com/pubweb/market/Rubbl-TOS.pdf' target='_blank' rel='nofollow'>Learn more</a>",
      ],
    },
  ],
} as GlossarySection;

export const PreventativeMaintenanceData: GlossarySection = {
  anchorId: "maintenance",
  header: "Preventative Maintenance",
  items: [
    {
      header: "Overview",
      body: ["Rubbl provides properly-maintained machines by coordinating mandatory ongoing preventative maintenance throughout the length of every rental."],
      bulletPoints: [
        "Machine usage tracking will define preventative maintenance intervals which are proactively scheduled for every 250 hours of use at each 250 hour and 500 hour period",
        "All standard preventative maintenance services will be the responsibility of the equipment Owner (Rubbl can conduct these services for a fee debited from the Owner’s rental proceeds)",
      ],
    },
    {
      header: "Preventative Maintenance Services Include:",
      bulletPoints: [
        "50-point inspection and services record review",
        "Fuel, oil, and coolant checks",
        "Hydraulic filter replacement (500 + 1500-hour intervals only)",
        "Oil & filter replacement using OEM parts",
        "Diagnostic and condition monitoring",
        "Free non-critical and/or suggested future repairs report",
      ],
    },
    {
      header: "Daily Walk-Around Inspection Responsibilities",
      body: [
        "Renters are responsible for ensuring all operators conduct a daily machine walk-around inspection and greasing, and that radiators and air filters are properly cleaned (blown out) as defined by the manufacturer’s operation manual (found in the glove box or behind the set of every machine). ",
        "Rubbl maintains that Renter will be held fully responsible for conducting these daily walkarounds and greasings. The Renter will automatically be held responsible for any damage sustained to equipment that can be attributed to non-compliance of these daily actions. These damages will automatically be charged to the Renter.",
      ],
    },
    {
      header: "Post Rental Cleaning and Prep",
      body: [
        "Renters are responsible for cleaning and prepping all rented machines before transportation pick-up. Prep must be done to return the gear at the same level to which it was received, including but not limited to the following areas:",
        "- Cab must be cleaned",
        "- Undercarriage areas must be cleaned",
        "- Tracks and/or tires must be cleaned",
        "- Machine must be returned fully FUELED",
        "- Machine must be returned with DEF fluid full",
        "- Air filters must be cleaned/blown out ",
        "- Radiators must be cleaned/blown out",
        "Attachments",
        "- Hammers must be properly greased",
        "- Hydraulics must be bled",
        "- Wearables (e.g., cutting edges, bucket teeth, hammer bits) must be replaced if showing greater than 75% wear",
        "If the machine is returned without being cleaned and prepped to the proper levels mentioned above, the Renter will be charged on the following schedule:",
        "- Deep Clean: $1,000",
        "- Medium to minor cleaning: $500",
        "- Fuel: @ Retail rate plus a refill surcharge fee",
        "- Def fluid: @ retail rate plus a refill surcharge fee",
        "- Wearables: @ retail replacement prices plus labor @ $145.00 an hour",
      ],
    },
    {
      header: "Jobsite Service Access",
      body: [
        "Renter agrees to give (or get) Rubbl formal written approval from jobsite management to allow certified Rubbl service providers open access to maintain and service any Rubbl rented gear while on-site whenever necessary. Renter also agrees to provide Rubbl with any site specific safety training and PPE required before machine usage.",
        "In the event of a necessary job site maintenance or repair visit, Rubbl will ensure that Renter is given as much notice possible in order to make arrangements.",
        "Rubbl also requires jobsite management to make Rubbl rented equipment available after hours, during mornings, nights, and weekends or when feasible.",
        "Rubbl will ensure that the jobsite contact receives ongoing communication about any maintenance and service activities, including but not limited to:",
      ],
      bulletPoints: [
        "The name of the individual performing services",
        "That entity’s qualifications and certifications",
        "what services are being performed",
        "arrival/activity/departure estimates",
      ],
    },
  ],
} as GlossarySection;

export const TransportationData: GlossarySection = {
  anchorId: "transportation",
  header: "Transportation",
  items: [
    {
      header: "Quote",
      body: ["Transportation quotes will be sent for each load within 24 hours of order confirmation. "],
    },
    {
      header: "Billing",
      body: [
        "Transportation costs will appear on a billing statement shortly after each machine has been delivered to the job site. In some cases, due to unforeseen circumstances, final costs may differ from the original quote. Any changes will be communicated to you immediately.",
      ],
    },
    {
      header: "Notifications",
      body: ["You will receive email and text message notifications throughout the delivery of each machine."],
    },
  ],
} as GlossarySection;

export const CreditBillingPaymentsData: GlossarySection = {
  anchorId: "payments",
  header: "Credit, Billing, & Payments",
  items: [
    {
      header: "Credit Approval",
      body: [
        "Credit approvals are automatically run during every checkout to ensure a safe and secure rental experience. The credit approval process does not impact yours or your company's credit score and is conducted only once a year.",
        "Annual credit checks are mandatory to rent and are provided by <a href='https://www.experian.com' target='_blank' rel='nofollow'>Experian</a>.",
      ],
    },
    {
      header: "Job or PO Number",
      body: [
        "A job or PO number can be entered for each rental to enable an accounts payable department to tie equipment rental costs to a specific job or project.",
      ],
    },
    {
      header: "Payment Set-Up",
      body: [
        "Payment instructions will be emailed to the payment contact that you provide during checkout. Payment set up of an ACH(bank account) and/or credit card is a required one-time step.",
      ],
    },
    {
      header: "Payment Methods",
      body: ["We require an ACH (bank account) or credit card to be set up within 72 hours of rental order confirmation."],
    },
    {
      header: "Billing Cycle",
      body: ["You will be automatically charged every 28 days after the first machine in an order has been delivered to the job site."],
    },
    {
      header: "Usage-Based Billing",
      body: ["You will be charged based on the following industry schedule:"],
      bulletPoints: [
        "3 days rolls up to one week",
        "3 weeks rolls up to one month",
        "Weekly Example: A 10 day rental equates to 1 week and 3 days. The additional 3 days rolls up to 1 week. The total charge for this rental will be 2 weeks.",
        "Monthly Example: A 17 day rental equates to 2 weeks and 3 days. The additional 3 days rolls up to 1 week. This brings the total weeks to 3.The total charge for this rental will be 1 month.",
        "Rentals that go beyond a 1-month billing cycle, will be charged pro-rata based on a 28-day month. Rubbl will calculate the daily pro-rata rate using the following formula: Monthly Rental Rate / 28 Days = Daily Pro-Rata Rate",
      ],
    },
    {
      header: "Billing Statements",
      body: ["You can view rental activity in real-time by accessing the billing statements online from the Rubbl account."],
    },
  ],
} as GlossarySection;

export const DamageCoverage: GlossarySection = {
  anchorId: "damageOverview",
  header: "Damage Coverage",
  items: [
    {
      header: "Overview",
      body: [
        "Our comprehensive equipment damage waiver covers <b>most causes of accidental damage.</b>",
        "<span class='bold fs-18 text-dark'>Program Benefits</span>",
      ],
      bulletPoints: [
        "No out-of-pocket costs for damage above $2,500",
        "No impact to existing insurance policy or premiums",
        "Provides <b>primary damage coverage</b> while gear is on rent",
      ],
    },
    {
      header: "Per Machine Deductible",
      body: [
        "If damage exceeds $2,500, a deductible of $2,500 will be charged for each machine that is damaged. Any damage below $2,500 will be the responsibility of the Renter.",
      ],
      bulletPoints: [
        "Proof of general liability coverage is required",
        "If the equipment is damaged while on rent, contact Rubbl immediately",
        "Failure to report serious damage within a business day could invalidate the damage coverage",
      ],
      asterisks: ["Damage Coverage provided by JT Bates Group"],
    },
    {
      header: "What's Covered?",
      body: [
        "Our damage coverage includes an All-Risk policy, which means <b>most accidental damage</b> is covered unless misuse is found or it’s specifically stated as excluded. Some examples of covered damage include:",
      ],
      bulletPoints: [
        "Accidental damage",
        "Collisions",
        "Earthquake",
        "Falling objects",
        "Fire",
        "Flood",
        "Hail",
        "Ingestion",
        "Overturns",
        "Theft",
        "Tornado",
        "Vandalism",
        "Other non-excluded perils",
      ],
    },
    {
      header: "What’s excluded?",
      body: [
        "The following types of damage and loss are excluded from our policy. All replacement or repair costs due to these exclusions are the responsibility of the Renter.",
      ],
      bulletPoints: [
        "Blatant misuse or obvious disregard for the safe operation of the equipment",
        "Civil authority: seizure, confiscation, destruction, or quarantine of property",
        "Contamination or deterioration: corrosion, decay, fungus, mildew, mold, rust, etc.",
        "Criminal, fraudulent, dishonest, or illegal acts: use and damage while committing a crime",
        "Loss of use: delay or loss of market",
        "Manufacturers’ specifications: use of machines outside of manufacturers’ specifications (i.e., overloading, improper fluids, etc.)",
        "Mechanical breakdown: mechanical structure, electrical breakdown or malfunction",
        "Missing property: unexplained or mysterious disappearance (i.e., shortage of property discovered on taking inventory)",
        "Nuclear hazard: nuclear reaction, radiation, or radioactive contamination",
        "Pollutants: release, discharge, seepage, migration, dispersal, or escape of pollutants",
        "Temperature or humidity: dryness, dampness, humidity, or changes in or extremes of temperatures",
        "Transit: machines are not covered during transit",
        "Voluntary parting with title",
        "War and military action: war, warlike action by a military force, insurrection, revolution",
        "Wear and tear",
        "Weight of load",
      ],
    },
  ],
} as GlossarySection;

export const RepairAndDamage: GlossarySection = {
  anchorId: "repairanddamage",
  header: "Repair & Damage Reconciliation",
  items: [
    {
      header: "Inspection Reports",
      body: [
        "A trained Rubbl service representative will review all inspection reports to determine the cause of damage and the responsible party based on what is found and when it was found during the rental lifecycle.",
        "<span class='bold fs-18 text-dark'>Machine Downtime Swaps</span>",
        "Machines down in the field that cannot be repaired despite best efforts will be replaced within 4 days from the point of breakdown.",
        "If the <b>Owner</b> is responsible for machine failure:",
        "- They will be responsible for like-machine replacement",
        "- If the Owner does not have a similar machine, a Rubbl account manager will use their best efforts to find a similar replacement machine on the marketplace",
        "- The Owner will be responsible for transporting the machine back to their yard",
        "- The Renter will <b>be credited</b> for the days taken to replace the down machine",
        "If the <b>Renter</b> is responsible for machine failure:",
        "- Rent for that machine will continue until a replacement machine has arrived onsite and is ready to work",
        "- The same Owner will get the right of first refusal to send a like machine, at the Renter’s expense",
        "- They will be responsible for the cost of transporting the down machine to the Owner’s requested service provider or back to the Owner’s yard (whichever the Owner chooses)",
      ],
    },
    {
      header: "Damage Claims",
      body: [
        "Rubbl will manage the entire damage claims process. Once submitted, Rubbl will review all check-out/in inspections submitted for that rental to determine if there is damage to that machine(s).",
        "If damage is found, Rubbl will assign a responsible party based on all four (4) inspection reports, maintenance records, undercarriage and oil sample reports.",
        "Rubbl will contact the responsible party immediately via a damage notification which will provide all details about the damage, all inspection reports, and a damage quote from a Rubbl certified service provider.",
        "If the responsible party is the Renter, the Rubbl certified provider will conduct the repairs at the expense of the Renter. Rubbl will manage this entire process, while keeping both parties updated about current status changes. Damage charges will appear on the next billing cycle statement as a line item charge. Both parties will have access to the damage report, the damage reconciliation quote, as well as the final work done.",
        "If damage exceeds $2,500, a damage waiver claim will be submitted to JT Bates Insurance Group on behalf of the Renter. Once the repair has been completed, Rubbl will charge the responsible party a deductible of $2,500 which will appear on the next billing statement.",
        "If the damage is the fault of the Renter, Rubbl reserves the right to continue charging rent on that piece of equipment while it's being repaired to help the machine Owner recoup losses for the down machine.",
      ],
    },
    {
      body: [],
    },
  ],
} as GlossarySection;

export const InspectionsData: GlossarySection = {
  anchorId: "inspections",
  header: "Inspections",
  items: [
    {
      header: "Overview",
      body: [
        "The Rubbl Inspect mobile app will be used by Rubbl, Renters, and Owners at strategic points in the rental process. The App is used to record the exact state of the equipment before it changes possession. Failure to complete any inspection promptly, accurately, and thoroughly may subject you to liability in the event of equipment damage. ",
      ],
    },
    {
      header: "Onboarding Inspections",
      body: [
        "Rubbl Inspect is used to thoroughly inspect and approve each piece of equipment for onboarding onto the Rubbl Marketplace.",
        "Each onboarding inspection includes:",
      ],
      bulletPoints: [
        "Comprehensive 50-point inspection using the Rubbl Inspect mobile app",
        "Gear condition recording via photographs, videos, and written notes by inspectors",
        "Inspection issue and resolution tracking with alerting to Rubbl Service Personnel",
        "Formal Rubbl review of all inspections to get approved gear listed quickly",
      ],
    },
    {
      header: "Check-In & Check-Out Inspections",
      body: [
        "Rubbl Inspect will again be used to conduct thorough inspections at four points in every rental, giving both the equipment Owner and the equipment Renter the ability to record the gear’s condition at that point in the rental.",
        "Each check-in & check out inspection includes:",
      ],
      bulletPoints: [
        "Comprehensive 50-point inspection using the Rubbl Inspect mobile app",
        "Gear condition recording via photographs, videos, and written notes by inspectors",
        "Formal Rubbl review of all check-in & out inspections and photos in case of damage",
      ],
    },
  ],
} as GlossarySection;
