<div class="header-overlay" style="overflow: scroll" *ngIf="showOverlay">
  <div class="header-overlay-container">
    <ul class="list-unstyled main-menu">
      <li *ngFor="let item of menuItems; last as isLast" [routerLinkActive]="['active']">
        <ng-container *ngIf="item.label != null">
          <a
            class="text-white fs-22 d-flex align-items-center"
            *ngIf="item.route != null"
            href
            [routerLink]="item.route"
            [attr.route]="item.route"
            title="{{ item.label }}"
          >
            {{ item.label }} <span *ngIf="item.new" class="badge badge-primary ml-3">New</span>
          </a>
          <a class="text-white fs-22" *ngIf="item.action != null" (click)="item.action()" title="{{ item.label }}">
            {{ item.label }}
          </a>
        </ng-container>

        <div class="d-flex flex-wrap text-white" [ngClass]="{ 'bb mb-3': !isLast, 'mt-3': !item.children }">
          <div class="mb-2" *ngFor="let subnavItem of item.children; last as isLast2">
            <a *ngIf="subnavItem.route" [routerLink]="[subnavItem.route]">{{ subnavItem.label }}</a>

            <a *ngIf="subnavItem.action != null" (click)="subnavItem.action()" title="{{ subnavItem.label }}">
              {{ subnavItem.label }}
            </a>
            <span class="mx-2" *ngIf="!isLast2">|</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
