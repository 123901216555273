import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncate",
})
export class TruncateStringPipe implements PipeTransform {
  transform(value: string, maxLength: number = 30, suffix: string = ""): string {
    if (value.length > maxLength) {
      return value.slice(0, maxLength) + "..." + suffix;
    }
    return value;
  }
}
