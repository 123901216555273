<div class="cart-details-container p-0 p-lg-4" rt="glossary-section-modal">
  <div class="d-flex justify-content-end">
    <div class="d-none d-lg-flex align-items-center icon-close-circle-container flex-column mr-lg-5" (click)="close()">
      <span class="icon-close text-grey"></span>
      <span class="text-grey supporting-text mt-1 d-none d-lg-block">Close</span>
    </div>

    <div class="d-lg-none close-button-container" (click)="close()">
      <span class="icon-close text-grey"></span>
      <span class="text-grey supporting-text mt-1 d-none d-lg-block">Close</span>
    </div>
  </div>
  <div class="d-lg-none custom-modal-header section-header w-100 bb-light">{{header}}</div>
  <div class="d-none d-lg-block bb-light my-4"></div>
  <div class="modal-body">
    <h3 class="text-center mb-5 d-none d-lg-block">{{header}}</h3>
    <div class="container mt-4" *ngIf="ready" [@fadeInOut]>
      <common-glossary [glossarySections]="glossarySections"></common-glossary>
    </div>
  </div>
</div>
