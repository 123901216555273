import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { fadeInOut } from "../../../rubbl-shared/rubbl-shared";
import { GlossarySection } from "../glossary-section/glossary-section.model";

@Component({
  selector: "common-glossary-modal",
  templateUrl: "./glossary-modal.component.html",
  animations: [fadeInOut("250ms", "250ms")],
})
export class GlossaryModalComponent implements OnInit {
  ready: boolean;
  header: string;
  glossarySections: GlossarySection[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    setTimeout(() => (this.ready = true), 350);
  }

  close() {
    this.activeModal.close();
  }
}
